import React, { Component } from 'react';

class Contact extends Component {
    render() {
        return (
            <div className="container my-5 py-4 bg-dark text-light rounded shadow-lg">
                <h1 className="text-center mb-4 text-uppercase border-bottom pb-3">
                    <i className="bi bi-hammer"></i> Kontakt
                </h1>
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="text-uppercase mb-3">
                            <i className="bi bi-person-circle me-2"></i> Osobní údaje
                        </h4>
                        <p>
                            <i className="bi bi-person me-2"></i> <strong>Jméno:</strong> Petr Doležal
                        </p>
                        <p>
                            <i className="bi bi-house me-2"></i> <strong>Adresa:</strong><br />
                            Kostníky 41<br />
                            p. Třebelovice<br />
                            675 32
                        </p>
                        <p>
                            <i className="bi bi-building me-2"></i> <strong>IČO:</strong> 07233299
                        </p>
                    </div>

                    <div className="col-md-6">
                        <h4 className="text-uppercase mb-3">
                            <i className="bi bi-envelope-paper me-2"></i> Kontakt
                        </h4>
                        <p>
                            <i className="bi bi-envelope me-2"></i> <strong>Email:</strong>{' '}
                            <a href="mailto:hugoknives@email.cz" className="text-info text-decoration-none">
                                hugoknives@email.cz
                            </a>
                        </p>
                        <p>
                            <i className="bi bi-chat me-2"></i> <strong>Messenger:</strong>{' '}
                            <a href="http://m.me/petr.dolezal.31" className="text-info text-decoration-none">
                                Přejít
                            </a>
                        </p>
                        <p>
                            <i className="bi bi-facebook me-2"></i> <strong>Facebook:</strong>{' '}
                            <a href="https://www.facebook.com/Petr-Dole%C5%BEal-HUGO-knives-136721603024851" className="text-info text-decoration-none">
                                Přejít
                            </a>
                        </p>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-12">
                        <h4 className="text-uppercase mb-3">
                            <i className="bi bi-bank me-2"></i> Bankovní spojení
                        </h4>
                        <p>
                            <i className="bi bi-credit-card me-2"></i> <strong>Číslo účtu:</strong>{' '}
                            <span className="fw-bold">115-8605030217/0100</span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;
