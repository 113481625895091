import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const LoadingScreen = () => {
    return (
        <div className="loading-screen">
            <div className="circle-container">
                <div className="circle circle1"></div>
                <div className="circle circle2"></div>
                <div className="circle circle3"></div>
            </div>
        </div>
    );
};

const Root = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 4000);
        document.body.classList.add('loaded');
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {loading ? <LoadingScreen /> : <App />}
        </>
    );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Root />
    </React.StrictMode>
);

reportWebVitals();
