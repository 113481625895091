import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const Gallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const images = Array.from({ length: 20 }, (_, i) => `/images/${i + 1}.jpg`);

    return (
        <div className="container my-5">
            <h1 className="text-center text-uppercase mb-4">Galerie</h1>
            <div className="row g-3">
                {images.map((image, index) => (
                    <div className="col-6 col-md-4 col-lg-3" key={index}>
                        <div
                            className="gallery-image position-relative overflow-hidden"
                            onClick={() => setSelectedImage(image)}
                            style={{
                                cursor: 'pointer',
                                borderRadius: '8px',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={image}
                                alt={`Gallery ${index + 1}`}
                                className="img-fluid"
                                style={{
                                    transition: 'transform 0.3s ease',
                                }}
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.1)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </div>
                    </div>
                ))}
            </div>

            {/* Modal pro zvětšení obrázku */}
            <Modal show={!!selectedImage} onHide={() => setSelectedImage(null)} size="lg" centered>
                <Modal.Body className="bg-dark text-center">
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Selected"
                            className="img-fluid rounded"
                        />
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Gallery;
