import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import NavbarMenu from "./components/Navbar";
import {Container} from 'react-bootstrap';

function App() {
  return (
    <Container className="mt-5">
      <React.StrictMode>
        <Router>
            <NavbarMenu />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
        </Router>
      </React.StrictMode>
    </Container>
  );
}

export default App;
