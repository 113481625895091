import React, {Component} from 'react';
import logo from "../img/logo.png";
import { Link } from "react-router-dom";

class NavbarMenu extends Component {
    render() {
        return (
            <div className="text-start">
                <Link to="/"><img style={{maxWidth: "150px"}} src={logo} /></Link>
            </div>
        );
    }
}

export default NavbarMenu;