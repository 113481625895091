import React, {Component} from 'react';
import "../index.css";
import { Link } from "react-router-dom";

class Home extends Component {
    render() {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <div className="fs-1 my-3">
                    <Link to="/gallery">Galerie</Link>
                </div>
                <div className="fs-1 mb-5 mt-3">
                    <Link to="/contact">Kontakt</Link>
                </div>
            </div>
        );
    }
}

export default Home;